import * as React from "react"
import { FC, useState } from "react"
import styled from "styled-components"
import LPBanner6 from "../components/banner/LPBanner6"
import EnquiryFormModal from "../components/lead-capture/enquiry-form/EnquiryFormModal"
import LPLeadMagnetModal from "../components/lead-capture/lead-magnet/LPLeadMagnetModal"
import LPOptionsFeatureBlock, { OptionType } from "../components/general/LPOptionsFeatureBlock"
import { navigate } from "gatsby"
import LPReviewsRatingBlock from "../components/reviews/LPReviewsRatingBlock"
import LPImageFeatureBlock from "../components/general/LPImageFeatureBlock"
import LPTitleFeatureBlock from "../components/general/LPTitleFeatureBlock"
import LPCorporateQuotesBlock from "../components/reviews/LPCorporateQuotesBlock"
import SectionHeader from "../../SectionHeader"
import BlogFeed from "../../BlogFeed"
import LPDoubleCTABlock, { DoubleCTAItemType } from "../components/cta/LPDoubleCTABlock"

const Container = styled.div``

const mastheadListItems = [
  "B Corp and purpose-driven products to unlock more social impact",
  "Celebrate your B Corp certification and other key moments",
  "Same-day response tailored to your brief & budget",
]

const getDoubleCtaItems = (openModal: () => void): DoubleCTAItemType[] => {
  return [
    {
      title: "Shop now",
      description: `Be a part of the B Corp movement right now and find a mission-led swap for everything from food to accessories, beauty to green cleaning.`,
      onClick: () => navigate("/impact/b-corp"),
      buttonLabel: "Shop B Corp",
    },
    {
      title: "Get in touch",
      description: `Speak to us about getting B Corp products into your team and clients' hands to align your company gifting with your values.`,
      onClick: openModal,
      buttonLabel: "Get in touch",
    },
  ]
}

const getOptions = (onModal: () => void): OptionType[] => [
  {
    title: "B Corp Gift Boxes & Hampers",
    description:
      "Send a gift that contains only B Corp certified food, drink and more – all packaged and fulfilled by another B Corp, Social Supermarket.",
    imageSrc: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/B-Corp-gift-new.png",
    secondaryOnClick: () => onModal(),
    secondaryLabel: "Find Out More",
  },
  {
    title: "B Corp Branded Merchandise",
    description:
      "Welcome new joiners to the team or gifts clients with merch that not only reflects your values, but is branded with your company logo or message.",
    imageSrc: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/B-Corp-LP-New.png",
    secondaryOnClick: () => onModal(),
    secondaryLabel: "Find Out More",
  },

  {
    title: "B Corp Food & Drink",
    description:
      "Individual items for desk drops or regular deliveries for workplace snacks can come from B Corporations too.",
    imageSrc:
      "https://data.socialsupermarket.org/wp-content/uploads/2018/10/B-Corp-LP-Food-and-Drink.png",
    secondaryOnClick: () => onModal(),
    secondaryLabel: "Find Out More",
  },
]

const StyledBlogFeed = styled(BlogFeed)``

interface Props {}

const BCorpGiftsBrandedMerchLP: FC<Props> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showLeadCaptureModal, setShowLeadCaptureModal] = useState<boolean>(false)
  return (
    <Container>
      <LPBanner6
        title={"B Corp gifts and branded merch"}
        checklistItems={mastheadListItems}
        actionLabel={"Get In Touch"}
        onAction={() => setShowModal(true)}
      />
      <LPOptionsFeatureBlock options={getOptions(() => setShowModal(true))} />
      <LPReviewsRatingBlock />
      <LPImageFeatureBlock
        title={<>How Simplyhealth & B Corp celebrated 1000 UK B Corps </>}
        description={
          "As a key partner of the B Corp #1000Better celebration of 1,000 UK B Corps in November 2022, Simplyhealth partnered with Social Supermarket to deliver wellbeing gifts that helped to support artisans in India and fund a literacy lesson in Madagascar."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Simplyhealth-Blog-feature-image.png"
        }
        to={"/2024/04/how-simplyhealth-celebrated-1000-b-corps-with-wellbeing-gifts"}
        colorWay={0}
      />
      {/*<LPTitleFeatureBlock*/}
      {/*  title={*/}
      {/*    "Solve social and environmental issues by purchasing from the UK's top social enterprises and mission-led businesses."*/}
      {/*  }*/}
      {/*  actionText={"View Brochure"}*/}
      {/*  onClick={() => setShowLeadCaptureModal(true)}*/}
      {/*/>*/}
      <LPDoubleCTABlock items={getDoubleCtaItems(() => setShowModal(true))} />
      <StyledBlogFeed
        titleAlign="left"
        categorySlug={"b-corp"}
        header={
          <SectionHeader
            subheading="Latest Blogs"
            title="What’s on our mind?"
            link="/blog"
            linkLabel="Go to our blog page "
          />
        }
        isDateVisible
      />
      <LPCorporateQuotesBlock />

      <LPLeadMagnetModal
        config={{
          popupTitle: "Download our Brochure",
          popupDescription: "Please provide your details to download our brochure.",
          eventName: "DownloadMerchBrochure",
          analyticsEventName: "brochure_download_merch",
          fileUrl:
            "https://www.canva.com/design/DAF8TjF29nI/ZNhW0FqO1tnr9gSXlC4YFw/edit?utm_content=DAF8TjF29nI&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
          buttonTitle: "Download Brochure",
        }}
        show={showLeadCaptureModal}
        onClose={() => setShowLeadCaptureModal(false)}
      />
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry"}
        name={"BCorpEnquiryForm"}
      />
    </Container>
  )
}

export default BCorpGiftsBrandedMerchLP
