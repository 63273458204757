import * as React from "react"
import styled from "styled-components"
import OutlinedInput from "../../../../../form/OutlinedInput"
import { PrimaryButton, validation } from "@social-supermarket/social-supermarket-components"
import { FormEvent } from "react"

const Container = styled.form``

const Row = styled.div`
  display: flex;
`
const Left = styled.div`
  flex: 1;
  margin-right: 5px;
`
const Right = styled.div`
  flex: 1;
  margin-left: 5px;
`

export const DEFAULT_LEAD_MAGNET_FORM_STATE: LeadMagnetFormStateType = {
  email: "",
  firstName: "",
  lastName: "",
  subscribe: true,
}

export interface LeadMagnetFormStateType {
  email: string
  firstName: string
  lastName: string
  subscribe: boolean
}

interface Props {
  state: LeadMagnetFormStateType
  onChange: (name: string, value: string) => void
  name: string
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
}

const LeadMagnetForm = ({ state, onChange, name, onSubmit }: Props) => {
  return (
    <form name={name} id={name} onSubmit={onSubmit}>
      <Row>
        <Left>
          <OutlinedInput
            validate={false}
            value={state.firstName}
            name={"firstName"}
            onChange={onChange}
            type={"text"}
            isValid={true}
            label={"First name"}
          />
        </Left>
        <Right>
          <OutlinedInput
            validate={false}
            value={state.lastName}
            name={"lastName"}
            onChange={onChange}
            type={"text"}
            isValid={true}
            label={"Last name"}
          />
        </Right>
      </Row>
      <OutlinedInput
        validate={false}
        value={state.email}
        name={"email"}
        onChange={onChange}
        type={"email"}
        isValid={true}
        label={"Email"}
      />
      <PrimaryButton disabled={!LeadMagnetForm.isValid(state)} fullWidth>
        Download
      </PrimaryButton>
    </form>
  )
}

LeadMagnetForm.isValid = (state: LeadMagnetFormStateType) => {
  return state.firstName && state.lastName && validation.validEmailFormat.test(state.email)
}

export default LeadMagnetForm
