import * as React from "react"
import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { Modal, PrimaryButton, Spinner } from "@social-supermarket/social-supermarket-components"
import * as tracker from "../../../../../../tracking/tracker"
import LeadMagnetForm, {
  DEFAULT_LEAD_MAGNET_FORM_STATE,
  LeadMagnetFormStateType,
} from "./LeadMagnetForm"
import { leadMagnet } from "../../../../../../api/leadCaptureApi"
import { LeadCaptureConfigType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div``
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`
const Description = styled.div`
  font-size: 0.8em;
  margin-bottom: 20px;
`
const Body = styled.div`
  margin-bottom: 30px;
  padding: 30px;
  padding-bottom: 0px;
`
const StyledModal = styled(Modal)`
  && {
    max-width: 400px;
    height: auto;
  }
`

interface Props {
  config: LeadCaptureConfigType
  show: boolean
  onClose: () => void
  openUrl?: string
}

const LPLeadMagnetModal: FC<Props> = ({ show, onClose, openUrl, config }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [state, setState] = useState<LeadMagnetFormStateType>({ ...DEFAULT_LEAD_MAGNET_FORM_STATE })

  useEffect(() => {
    if (show) {
      tracker.modalOpened()
    }
  }, [show])

  const handleClose = () => {
    onClose()
    setIsSuccess(false)
  }

  const onSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await leadMagnet(state, config.eventName)
      if (response.status === "SUCCESS") {
        setIsSuccess(true)
        tracker.trackConversion(config.analyticsEventName, 5)
        if (openUrl) {
          window.open(openUrl, "_blank")
        }

        setTimeout(() => {
          handleClose()
        }, 5000)
      } else {
        alert(response.message)
      }
    } catch (e) {
      alert(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (name, value) => {
    setState(prev => ({ ...prev, [name]: value }))
  }

  return (
    <StyledModal show={show} onClose={handleClose} hideHeader>
      <Spinner isLoading={isLoading} label={"Submitting details.."} />
      {!isSuccess && (
        <Body>
          <Title>{config?.popupTitle}</Title>
          <Description>{config?.popupDescription}</Description>
          <LeadMagnetForm
            name={config.eventName}
            state={state}
            onChange={handleChange}
            onSubmit={onSubmit}
          />
        </Body>
      )}
      {isSuccess && <Body>Thank you, the download is on its way to your email.</Body>}
    </StyledModal>
  )
}

export default LPLeadMagnetModal
